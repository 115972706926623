import React from "react";
import styles from "./Section3.module.scss";
import BgImg1 from "../../../assets/images/homeSec3BgImg1.jpg";
import BgImg2 from "../../../assets/images/homeSec3BgImg2.jpg";
import BgImg3 from "../../../assets/images/homeSec3BgImg3.jpg";
import BgImg4 from "../../../assets/images/homeSec3BgImg4.jpg";
import BgImg5 from "../../../assets/images/homeSec3BgImg5.jpg";
import BgImg6 from "../../../assets/images/homeSec3BgImg6.jpg";
import useScrollReveal from '../../../hooks/useScrollReveal'; // Assuming the path is correct

function Tile({ title, body, bgImage, direction }) {
  const [tileRef] = useScrollReveal(direction);

  return (
    <div
      ref={tileRef}
      className={styles.tileContainer}
      style={{ backgroundImage: `url(${bgImage})` }}
    >
      <div className={styles.textContent}>
        <h1 className={styles.textTitle + " textTitle josefin "}>{title}</h1>
        <p className={styles.bodyText + " bodyText josefin"}>{body}</p>
      </div>
    </div>
  );
}

function Section3() {
  const tilesData = [
    { title: "SKY CONODOMINIUMS", body: "Two tastefully crafted units on each floor, an indulgent 20 feet apart.", bgImage: BgImg1 },
    { title: "PLATINUM GRADE WELLNESS CLUBS", body: "Exceptional triple clubhouse for individual idiosyncrasies and for community camaraderie.", bgImage: BgImg2 },
    { title: "stately towers", body: "Duplet Towers that flawlessly elevate your privacy & lifestyle.", bgImage: BgImg3 },
    { title: "proximity perfected", body: "Nestled 400m from Wipro SEZ and manifold tech parks.", bgImage: BgImg4 },
    { title: "Kindred Community", body: "Steep in harmony with minds that resonate.", bgImage: BgImg5 },
    { title: "PRIVATE garden", body: "Embellished with a garden space for each illustrious unit.", bgImage: BgImg6 },
  ];
  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
        {tilesData.map((tile, index) => {
          const direction = index % 2 === 0 ? 'left' : 'right';  // Determine direction based on the index
          return (
            <Tile 
              key={index}
              title={tile.title}
              body={tile.body}
              bgImage={tile.bgImage}
              direction={direction}
            />
          );
        })}
      </div>
    </div>
  );
}

export default Section3;