import React from "react";
import styles from "./Section8.module.scss";
import OutdoorWorkingPad from "../../../assets/icons/OutdoorWorkinPad.svg";
import LeisurePad from "../../../assets/icons/LeisurePad.svg";
import PetsCorner from "../../../assets/icons/PetsCorner.svg";
import ApmphiTheatre from "../../../assets/icons/AmphiTheatre.svg";
import BasketballCourt from "../../../assets/icons/BasketballCourt.svg";
import OutdoorGym from "../../../assets/icons/OutdoorGym.svg";
import MeditationZone from "../../../assets/icons/MeditationZone.svg";
import ButterflyGarden from "../../../assets/icons/ButterflyGarden.svg";
import herbalGarden from "../../../assets/icons/herbalGarden.svg";
import SeniorCitizenCourt from "../../../assets/icons/SeniorCitizenCourt.svg";
import ToddlersZone from "../../../assets/icons/ToddlersZone.svg";

function Section8() {
  const iconsData = [
    { src: OutdoorWorkingPad, alt: "Outdoor Gym", iconText: "Outdoor Gym" },
    { src: LeisurePad, alt: "Leisure Pad", iconText: "Leisure Pad" },
    { src: PetsCorner, alt: "Pets Corner", iconText: "Pets Corner" },
    { src: ApmphiTheatre, alt: "Amphitheatre", iconText: "Amphitheatre" },
    { src: BasketballCourt, alt: "Basketball Court", iconText: "Basketball Court" },
    { src: OutdoorGym, alt: "Outdoor Gym", iconText: "Outdoor Gym" },
    { src: MeditationZone, alt: "Meditation Zone", iconText: "Meditation Zone" },
    { src: ButterflyGarden, alt: "Butterfly Garden", iconText: "Butterfly Garden" },
    { src: herbalGarden, alt: "Herbal Garden", iconText: "Herbal Garden" },
    { src: SeniorCitizenCourt, alt: "Senior Citizen Court", iconText: "Senior Citizen Court" },
    { src: ToddlersZone, alt: "Toddlers Zone", iconText: "Toddlers Zone" },
  ];

  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
        <h1 className={styles.textTitle + " textTitle lunativeFont"}>
          Access To Amenities
        </h1>
        <p className={styles.bodyText}>Indulge in a symphony of exquisite offerings.</p>
        <div className={styles.iconsDiv}>
          {iconsData.map((icon, index) => (
            <div key={index} className={styles.iconTextDiv}>
              <div className={styles.iconImgContainer}><img src={icon.src} alt={icon.alt} className={styles.icon}></img></div>
              <p className={styles.iconText}>{icon.iconText}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Section8;
