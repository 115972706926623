import React, { useState, useEffect, useRef } from "react";
import styles from "./Section11.module.scss";
import ManaLogo2 from "../../../assets/logo/ManaLogo2.png";

function Section11() {
  const rootDivRef = useRef(null);
  const [isInView, setIsInView] = useState(false);
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isInView) {
          console.log("Section11 is in view!");
          setIsInView(true);
        }
      },
      {
        threshold: 0.1,
      }
    );

    if (rootDivRef.current) {
      observer.observe(rootDivRef.current);
    }

    return () => {
      if (rootDivRef.current) {
        observer.unobserve(rootDivRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!isInView) return;

    console.log("Starting animation...");

    const duration = 1000;
    const startTime = Date.now();

    const intervalId = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const currentProgress = elapsedTime / duration;
      // console.log("Progress:", currentProgress);

      if (currentProgress < 1) {
        setProgress(currentProgress);
      } else {
        setProgress(1);
        clearInterval(intervalId);
      }
    }, 20);

    return () => {
      clearInterval(intervalId);
    };
  }, [isInView]);

  const labelValueData = [
    { label: "years of experience", value: 23, endValue: "23+" },
    { label: "projects", value: 29, endValue: "29+" },
    { label: "happy clients", value: 7000, endValue: "7000+" },
    { label: "SQ feet delivered", value: 8, suffix: "M", endValue: "8M+" },
    { label: "staff & contractors", value: 1000, endValue: "29+" },
  ];

  return (
    <div className={styles.rootDiv + " rootDiv "} ref={rootDivRef}>
      <div className={styles.secondDiv + " secondDiv "}>
        <div className={styles.textTitle + " textTitle lunativeFont "}>
          Who are we?
        </div>
        <div className={styles.imgDiv + "  "}>
          <img src={ManaLogo2} alt="Logo" />
        </div>
        <div className={styles.rootLabelValueDiv}>
          {labelValueData.map((data, index) => (
            <div key={index} className={styles.labelValueDiv + "  "}>
              <h1 className={styles.value + " lunativeFont "}>
                {Math.floor(data.value * progress)}
                {data.suffix ? data.suffix : ""}+
              </h1>
              <p className={styles.label}>{data.label}</p>
            </div>
          ))}
        </div>

        <div className={styles.grayTextDiv + "  "}>
          <p className={styles.grayText + "  "}>
            Our success as one of the leading builders in Bengaluru is not
            solely based on designing luxurious concrete apartments. Our main
            focus is on transforming the real estate industry by providing a
            unique customer experience. Although it is a challenging task, we
            believe it is achievable to meet the expectations of our clients.<br/><br/>
            Established in 2000, we have become a reputable construction company
            in Bengaluru, offering services in property development,
            construction, and infrastructure projects.
          </p>
        </div>
      </div>
    </div>
  );
}

export default Section11;
