import React from "react";
import styles from "./Section10.module.scss";
import useScrollReveal from '../../../hooks/useScrollReveal'; 
import useOnScrollMoveUp from '../../../hooks/useScrollEffect';  // Adjust the import path accordingly


function Section10() {
  const [leftRef, leftVisible] = useScrollReveal('left');
  const [rightRef, rightVisible] = useScrollReveal('right');
      const { elementRef, animationStyle } = useOnScrollMoveUp();

  
  const data = [
    { label: "Acres", value: "2" },
    { label: "Handover", value: "2026" },
    { label: "Type", value: "3/4/5 BHK" },
    { label: "Super Built-Up Area", value: "1000 - 2000 sqFt" },
    { label: "Total Units:", value: "G + 20 Floors | 40 Units - Each Tower" },
  ];
  return (
    <div ref={elementRef}  className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
      <div ref={leftRef} className={`${styles.leftDiv} appearFromLeft`}>
          <h1 style={animationStyle} className={styles.textTitle + " textTitle lunativeFont "}>
            Project Overview
          </h1>
        </div>
        <div ref={rightRef} className={`${styles.rightDiv} appearFromRight`}>
          {data.map((data, index) => (
            <div key={index} className={styles.textDiv + ""}>
              <p className={styles.label + " lunativeFont "}>{data.label}</p>
              <h1 className={styles.value}>{data.value}</h1>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Section10;
