/* global dataLayer */
import React, { useState } from "react";
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  getAuth,
} from "firebase/auth";
import { initializeApp } from "firebase/app";
import styles from "./Section6.module.scss";
import emailjs from "emailjs-com";
import { faCaretDown, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

function Section6({ onClose }) {
  const [emailError, setEmailError] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [isOtpSending, setIsOtpSending] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [resendCooldown, setResendCooldown] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const [resendButtonText, setResendButtonText] = useState("Resend OTP");

  const firebaseConfig = {
    apiKey: "AIzaSyBWPKjMILqN_jxdIFl_7zbpYypVcZGBrQY",
    authDomain: "credvest-projects.firebaseapp.com",
    projectId: "credvest-projects",
    storageBucket: "credvest-projects.appspot.com",
    messagingSenderId: "727766634738",
    appId: "1:727766634738:web:61e5b8d84b97dfd97f806b",
  };

  const app = initializeApp(firebaseConfig);
  const [step, setStep] = useState(0);
  const currentYear = new Date().getFullYear();
  const [emailWarning, setEmailWarning] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const [otp, setOtp] = useState("");
  const [verificationId, setVerificationId] = useState(null);

    // Define the gtag_report_conversion function
const gtag_report_conversion = (url) => {
  const callback = function () {
    if (typeof(url) != 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
      'send_to': 'AW-744349269/nRB1CI9Zu2wBEPj9tZ9C',
      'event_callback': callback
  });
  return false;
};

  
  const sendOtp = async () => {
    setIsOtpSending(true); // Set to true before sending OTP
    try {
      if (!window.recaptchaVerifier) {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth,
          "recaptcha-container",
          { size: "invisible" }
        );
        window.recaptchaVerifier.render();
      }
      // Check if the email is not valid
      if (!isValidEmail(formData.email)) {
        setEmailError("Please enter a valid email address.");
        setIsOtpSending(false); // Set back to false since OTP is not sent
        return; // Exit the function without sending OTP
      }
      if (formData.phoneNumber.length !== 10) {
        setPhoneNumberError("Invalid phone number.");
        setIsOtpSending(false); // Set back to false since OTP is not sent
        return; // Exit the function without sending OTP
      }
      // Send OTP using signInWithPhoneNumber
      const phoneNumberWithPrefix = `+91${formData.phoneNumber}`;
      const verificationId = await signInWithPhoneNumber(
        auth,
        phoneNumberWithPrefix,
        window.recaptchaVerifier
      );

      // Save the verificationId and update isOtpSent state
      setVerificationId(verificationId);
      setIsOtpSent(true); // Update the state to true after OTP is sent
      setIsModalOpen(true); // Open the modal after OTP is sent
    } catch (error) {
      console.error("Error sending OTP:", error);
    } finally {
      setIsOtpSending(false); // Set back to false after OTP is sent or fails
    }
  };
  const verifyOtp = async () => {
    setIsVerifyingOtp(true);
    try {
      const result = await verificationId.confirm(otp);

      handleFormSubmit(); // Call the form submission function directly
      navigate("/thankyou");
      gtag_report_conversion();
      setIsModalOpen(false); // Assuming you want to close the modal on success
    } catch (error) {
      console.error("Error verifying OTP:", error);
      setOtpError("Invalid OTP. Please try again."); // Set the error message
    } finally {
      setIsVerifyingOtp(false);
    }
  };

  const resendOtp = async () => {
    if (resendCooldown) {
      console.log("Please wait a moment before resending OTP");
      return;
    }

    setOtp(""); // Clear the OTP input field
    setResendCooldown(true);
    setResendButtonText(`OTP resent 30 sec`);

    // Start the countdown
    let counter = 30;
    const intervalId = setInterval(() => {
      counter -= 1;
      setResendButtonText(`Resend OTP in ${counter} sec`);

      if (counter === 0) {
        clearInterval(intervalId); // Stop the countdown
        setResendCooldown(false); // Enable the button
        setResendButtonText("Resend OTP"); // Reset button text
      }
    }, 1000);

    try {
      await sendOtp(); // Call sendOtp to resend the OTP
    } catch (error) {
      console.error("Error resending OTP:", error);
      clearInterval(intervalId); // Ensure to clear interval if there's an error
      setResendCooldown(false); // Re-enable the button if error occurs
      setResendButtonText("Resend OTP"); // Reset button text if error occurs
    }
  };

  const otpModal = (
    <div className={`${styles.modalOverlay} ${isModalOpen ? styles.show : ""}`}>
      <div className={styles.modalContent}>
        <div onClick={() => setIsModalOpen(false)} className={styles.closeIcon}>
          <FontAwesomeIcon icon={faTimes} />
        </div>{" "}
        <p>Enter the OTP sent to your number</p>
        <input
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          placeholder="Enter OTP"
          className={styles.input}
        />
        {otpError && <div style={{ color: "red" }}>{otpError}</div>}
        <div className={styles.modalButtons}>
          <button onClick={verifyOtp} className={styles.btn}>
            {isVerifyingOtp ? "Submitting..." : "Verify OTP and Submit"}
          </button>
          <button onClick={() => setIsModalOpen(false)} className={styles.btn}>
            Change the number
          </button>
        </div>
        <div className={styles.resendDiv}>
          <span>Didnt receive the OTP?</span>
          <p
            className={styles.resendOtp}
            onClick={resendOtp}
            disabled={resendCooldown}
          >
            {resendButtonText}
          </p>
        </div>
      </div>
    </div>
  );

  const yearOptions = Array.from(
    { length: currentYear - 1900 + 1 },
    (_, index) => currentYear - index
  );

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    residence: "",
    yearOfPurchase: "",
    phoneNumber: "",
    workplace: "",
    designation: "",
    dreamHouseElements: "",
    manaIntrigue: "",
  });
  function isValidEmail(email) {
    // This regex checks for the common structure of an email.
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    return emailRegex.test(email);
  }

  const handleFormSubmit = async () => {
    setIsSending(true);

    try {
      await emailjs.send(
        "service_1ae9ues",
        "template_7lvekxr",
        formData,
        "jC5nfyIGeHjQirhM8"
      );
      console.log("Email sent successfully");
      // Redirect to the thank you page
    } catch (error) {
      console.error("Error sending email:", error);
    } finally {
      // Step 3: Set isSending back to false when done
      setIsSending(false);
    }
  };


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "phoneNumber") {
      const digitsOnly = value.replace(/\D/g, ""); // Extract digits
      setIsPhoneNumberValid(digitsOnly.length === 10); // Validate length
      setFormData((prevState) => ({
        ...prevState,
        [name]: digitsOnly,
      }));
    } else {
      setFormData((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.thankyouLeft + " lunativeFont  "}> THANK YOU!</div>
      <div className={styles.secondDiv + " secondDiv "}>
        <span>General Info</span>
        <input
          type="text"
          name="name"
          placeholder="Name*"
          value={formData.name}
          onChange={handleInputChange}
          className={styles.input}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email*"
          value={formData.email}
          onChange={handleInputChange}
          className={styles.input}
          required
        />
        {emailWarning && (
          <div style={{ color: "red" }}>
            Please enter a valid email address.
          </div>
        )}

        <div className={styles.phoneNumberContainer + " " + styles.input}>
          <span className={styles.nonEditablePrefix}>+91</span>
          <input
            type="tel"
            name="phoneNumber"
            placeholder="10 Digit Phone Number*"
            value={formData.phoneNumber}
            onChange={handleInputChange}
            required
            pattern="\d{10}"
            maxLength="10"
            className={styles.telInput}
            title="Please enter a 10-digit phone number."
          />
        </div>
        {!isPhoneNumberValid && (
          <div style={{ color: "red" }}>
            Please enter a valid 10-digit phone number.
          </div>
        )}

        <button onClick={sendOtp} className={styles.btn}>
          {isOtpSending ? "Sending OTP..." : "Send OTP"}
        </button>
        {/* invalid phone number */}
        {phoneNumberError && (
          <div style={{ color: "red" }}>{phoneNumberError}</div>
        )}
        {/* invalid Email */}
        {emailError && <div style={{ color: "red" }}>{emailError}</div>}
        <div id="recaptcha-container"></div>
      </div>
      {otpModal}
    </div>
  );
}

export default Section6;
