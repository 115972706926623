import React from "react";
import styles from "./Section9.module.scss";

function Section9() {
  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
      <div className={styles.textTitleX + " textTitle lunativeFont "}>
          Pricing
        </div>
        <p className={styles.bodyText}>Starting @</p>
        <h1 className={styles.textTitle + " textTitle lunativeFont"}>
          <span>₹3.5 Cr</span> <span className={styles.tc}>T&C Apply</span>
        </h1>
        <p className={styles.graytext}>Elevate Your Living Standards</p>
      </div>
    </div>
  );
}

export default Section9;
