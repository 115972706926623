import React from "react";
import styles from "./Cookies.module.scss";
import { useNavigate } from "react-router-dom"; // Importing useNavigate

function Cookies() {
  const navigate = useNavigate(); // Initializing the navigate function

  // Handler for the 'Back' button which navigates to the previous page
  const goBack = () => {
    navigate(-1); // This goes back to the previous page
  };

  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
        <button onClick={goBack} className={styles.backButton}>
          Go Back
        </button>

        <h1>Cookie Policy</h1>
        <p className={styles.intro}>
          What are cookies? Cookies are small text files that are stored on your
          computer or mobile device when you visit a website. They allow the
          website to store your actions and preferences (such as login,
          language, font size, and other display preferences) over a period of
          time, so you don't have to keep re-entering them whenever you come
          back to the site or browse from one page to another.
        </p>

        <h2>How do we use cookies?</h2>
        <p>
          Our website uses cookies to improve your user experience by enabling
          the website to remember you, either for the duration of your visit
          (using a "session cookie") or for repeat visits (using a "persistent
          cookie"). We do not use cookies for tracking purposes or to collect
          personal information about you.
        </p>

        <h2>How to control cookies?</h2>
        <p>
          You can control and/or delete cookies as you wish. You can delete all
          cookies that are already on your computer and you can set most
          browsers to prevent them from being placed. If you do this, however,
          you may have to manually adjust some preferences every time you visit
          a site, and some services and functionalities may not work.
        </p>
      </div>
    </div>
  );
}

export default Cookies;
