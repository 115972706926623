import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import "./App.scss";
import styles from "./common.scss";

import Home from "./pages/Home/Home";
import PrivacyPage from "./pages/Privacy/Privacy";
import TermsPage from "./pages/Terms/Terms";
import Cookies from "./pages/Cookies/Cookies";
import ScrollToTop from "./hooks/ScrollToTop";
import ThankYou from "./pages/Thankyou/Thankyou";
import Contact from "./sections/Home/Section6/Section6";
import Aboutus from "./pages/aboutus/aboutus";
import Amenities from "./pages/amenities/amenities";
import VR from "./pages/vr/vr";

function App() {
  return (
    <Router>
      <ScrollToTop />
      <div className=" App ">
        <Routes>
          <Route
            path="/"
            element={
              <>
                <Helmet>
                  <title>Luxury Apartments in Sarjapur | Mana Jardin Neo</title>
                  <meta
                    name="description"
                    content="Super luxury apartments at Sarjapur by Mana Projects. 400m from Wipro SEZ. Request Callback."
                  />
                </Helmet>
                <Home />
              </>
            }
          />
          <Route
            path="/vr"
            element={
              <>
                <Helmet>
                  <title>Luxury Apartments in Sarjapur | Mana Jardin Neo</title>
                  <meta
                    name="description"
                    content="Super luxury apartments at Sarjapur by Mana Projects. 400m from Wipro SEZ. Request Callback."
                  />
                </Helmet>
                <VR />
              </>
            }
          />
          <Route
            path="/privacypolicy"
            element={
              <>
                <Helmet>
                  <title>Mana Jardin Neo - Privacy Policy</title>
                  <meta name="description" content="Read our privacy policy." />
                </Helmet>
                <PrivacyPage />
              </>
            }
          />
          <Route
            path="/termsofuse"
            element={
              <>
                <Helmet>
                  <title>Mana Jardin Neo - Terms of Service</title>
                  <meta
                    name="description"
                    content="Read our terms of service."
                  />
                </Helmet>
                <TermsPage />
              </>
            }
          />
          <Route
            path="/cookies"
            element={
              <>
                <Helmet>
                  <title>Mana Jardin Neo - Cookies</title>
                  <meta name="description" content="Read our Cookies Policy" />
                </Helmet>
                <Cookies />
              </>
            }
          />
          <Route
            path="/thankyou"
            element={
              <>
                <Helmet>
                  <title>Mana Jardin Neo - Thankyou!</title>
                  <meta name="description" content="Thankyou" />
                </Helmet>
                <ThankYou />
              </>
            }
          />
          <Route
            path="/contactus"
            element={<Navigate to="/#section2" replace />}
          />
          <Route
            path="/aboutus"
            element={<Navigate to="/#section11" replace />}
          />
          <Route
            path="/amenities"
            element={<Navigate to="/#section4" replace />}
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
