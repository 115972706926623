import React from "react";
import styles from "./Section13.module.scss";
import { Link } from "react-router-dom";
// Import FontAwesome components and specific icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";

function Section13({ onRegisterClick }) {
  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
        <h1 className={styles.textTitle + " textTitle lunativeFont"}>
          Find Your Place
        </h1>
        <button onClick={onRegisterClick} className={styles.btn + " btn "}>
          Request Callback
        </button>
        <div className={styles.bottomDiv}>
          <Link to="/privacypolicy">
            <a>Privacy Policy</a>
          </Link>
          <Link to="/termsofuse">
            <a> Terms of Use </a>
          </Link>
          <Link to="/cookies">
            <a target="_blank">Cookie Policy</a>
          </Link>
        </div>
        <a
          href="https://credvest.com/#/"
          target="blank"
          className={styles.poweredBy}
        >
          Powered by Credvest India Private Limited
        </a>
        <p className={styles.codeNameCrown}>Mana Jardin Neo</p>
        <div className={styles.social}>
          {/* Use FontAwesome icons */}
          <a className={styles.fb} href="https://www.facebook.com/manajardineo" target="_blank">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a className={styles.ig} href="https://www.instagram.com/manajardinneo" target="_blank">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Section13;
