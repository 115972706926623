import React from "react";
import styles from "./Section3p1.module.scss";
import bgImg from "../../../assets/images/HomeSec3p1Bg.jpg"

function Section3p1() {
  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
        <h1 className={styles.textTitle + " lunativeFont textTitle "}>Privilege</h1>
        <p className={styles.bodyText + "  "}>Is When You Have <span className={styles.textTitle + " lunativeFont textTitle "}>Access</span></p>
      </div>
    </div>
  );
}

export default Section3p1;
