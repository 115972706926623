import { useEffect, useState, useRef } from 'react';

const useScrollRevealText = (direction = 'left', earlyRevealOffset = 200) => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const elementHeight = rect.height;
      const visiblePart = window.innerHeight - rect.top;

      // Adjust the visiblePart condition to make the element appear sooner
      if (visiblePart + earlyRevealOffset > 0 && visiblePart < elementHeight) {
        const progress = Math.min((visiblePart + earlyRevealOffset) / elementHeight, 1);
        const translateValue = (1 - progress) * (direction === 'left' ? -100 : 100);
        ref.current.style.transform = `translateX(${translateValue}%)`;
      } else if (visiblePart + earlyRevealOffset <= 0) {
        ref.current.style.transform = `translateX(${direction === 'left' ? -100 : 100}%)`;
      } else {
        ref.current.style.transform = `translateX(0%)`;
      }
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [ref, isVisible];
};

export default useScrollRevealText;
