import { useState, useEffect, useRef } from 'react';

// Helper function to debounce function calls
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

const useOnScrollMoveUp = () => {
  const [scrollY, setScrollY] = useState(0);
  const elementRef = useRef(null);

  useEffect(() => {
    // Modify your handleScroll function
    const handleScroll = debounce(() => {
      requestAnimationFrame(() => {
        if (elementRef.current) {
          const elementTop = elementRef.current.offsetTop;
          const elementBottom = elementTop + elementRef.current.offsetHeight;
          const currentScroll = window.scrollY;
          if (currentScroll >= elementTop && currentScroll <= elementBottom) {
            setScrollY(currentScroll - elementTop);
          }
        }
      });
    }, 10);  // Debounce with a 10ms delay

    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const translateY = scrollY / 2;  // Adjust this value to control the speed of the animation
  const animationStyle = {
    transform: `translateY(-${translateY}px)`,
    willChange: 'transform',  // Inform the browser about the upcoming transform change
  };

  return {
    elementRef,
    animationStyle
  };
};

export default useOnScrollMoveUp;