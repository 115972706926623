import React, { useState, useRef, useEffect } from "react";
import styles from "./Home.module.scss";
import Section1 from "../../sections/Home/Section1/Section1";
import Section2 from "../../sections/Home/Section2/Section2";
import Section3 from "../../sections/Home/Section3/Section3";
import Section3p1 from "../../sections/Home/Section3p1/Section3p1";
import Section4 from "../../sections/Home/Section4/Section4";
import Section4p1 from "../../sections/Home/Section4p1/Section4p1";
import Section4p2 from "../../sections/Home/Section4p2/Section4p2";
import Section5 from "../../sections/Home/Section5/Section5";
import Section6 from "../../sections/Home/Section6/Section6";
import Section7 from "../../sections/Home/Section7/Section7";
import Section8 from "../../sections/Home/Section8/Section8";
import Section9 from "../../sections/Home/Section9/Section9";
import Section10 from "../../sections/Home/Section10/Section10";
import Section11 from "../../sections/Home/Section11/Section11";
import Section12 from "../../sections/Home/Section12/Section12";
import Section13 from "../../sections/Home/Section13/Section13";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { scroller } from "react-scroll";
import { useNavigate } from 'react-router-dom'; // Corrected import
import { faVrCardboard } from "@fortawesome/free-solid-svg-icons"; // Use an actual FontAwesome icon that suits your needs
import { faPhone } from "@fortawesome/free-solid-svg-icons";



function Home() {
  const [isSection6Visible, setSection6Visibility] = useState(false);
  const section6Ref = useRef(null);
  const section5Ref = useRef(null);
  const [showPopup, setShowPopup] = useState(false);
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };
  const navigate = useNavigate();


  const Popup = ({ onClose }) => {
    return (
      <div className={styles.popup}>
        <div className={styles.popupContent}>
          <FontAwesomeIcon
            onClick={onClose}
            icon={faTimes}
            className={styles.popupCloseBtn}
          />
          <Section6 />
        </div>
      </div>
    );
  };
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      scroller.scrollTo(hash.substring(1), {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
      });
      // Optionally, remove the hash from the URL after scrolling
      window.history.pushState("", document.title, window.location.pathname + window.location.search);
    }
  }, []);
  

  useEffect(() => {
    // Clear any hash from the URL
    if (window.location.hash) {
      window.history.pushState(
        "",
        document.title,
        window.location.pathname + window.location.search
      );
    }

    // Set the scroll position to the top
    window.scrollTo(0, 0);
  }, []);

  const showSection6 = () => {
    setSection6Visibility(true);
    // Wait for a rerender and then scroll smoothly to Section6
    setTimeout(() => {
      section6Ref.current.scrollIntoView({ behavior: "smooth" });
    }, 100);
  };

  const hideSection6 = () => {
    setSection6Visibility(false);
  };
  const showSection5 = () => {
    section5Ref.current.scrollIntoView({ behavior: "smooth" });
  };
  function handleVrClick() {
    navigate("/vr");
  }
  return (
    <div>

<div className={styles.fixedBtn}>
  <button onClick={handleVrClick} className={styles.vrBtn}>
  <FontAwesomeIcon className={styles.vrIcon} icon={faVrCardboard} />
    View VR
  </button>
        <button
          onClick={togglePopup}
          className={styles.contactBtn + " lunativeFont "}
        >
            <FontAwesomeIcon icon={faPhone} /> {/* This line adds the phone icon */}

          Enquire Now
        </button>
</div>
      {showPopup && <Popup onClose={togglePopup} />}
      <Section1 onRegisterClick={showSection5} />
      <div id="section2">
        <Section2 onRegisterClick={showSection6} />
      </div>
      <Section3 />
      <Section3p1 />
      <div id="section4">
        <Section4 onRegisterClick={showSection5} />
      </div>{" "}
      <Section4p1 onRegisterClick={showSection5} />
      <Section4p2 onRegisterClick={showSection5} />
      <div ref={section5Ref}>
        <Section5 onRegisterClick={showSection6} />
      </div>
      {/* this is the contact form that click on open */}
      <div ref={section6Ref}>
        {isSection6Visible && <Section6 onClose={hideSection6} />}
      </div>
      {/* <Section7 /> */}
      {/* <Section8 /> */}
      <Section9 />
      {/* <Section10 /> */}
      <div id="section11">
        <Section11 />
      </div>
      {/* <Section12 /> */}
      <Section13 onRegisterClick={showSection6} />
    </div>
  );
}

export default Home;
