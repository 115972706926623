import React from "react";
import styles from "./Section2.module.scss";
import rightImg from "../../../assets/images/HomeSec2RightImg.jpg";
import useScrollReveal from "../../../hooks/useScrollRevealText"; // Update the path
import ContactUs from "../Section6/Section6";

function Section2({ onRegisterClick }) {
  const [leftRef, leftVisible] = useScrollReveal("left");
  const [rightRef, rightVisible] = useScrollReveal("right");

  return (
    <div className={styles.rootDiv + " rootDiv sec2 "}>
      <div className={styles.secondDiv + " secondDiv "}>
        <h1
          ref={leftRef}
          className={
            styles.textTitle + " textTitle lunativeFont appearFromLeft"
          }
        >
          Unlock Privileged Living
        </h1>
        <p
          ref={rightRef}
          className={styles.bodyText + " bodyText appearFromRight "}
        >
           Introducing Jardin Neo, an exquisite residential enclave, luxury
          apartments near Wipro, meticulously crafted for the discerning few,
          nestled within this exclusive complex, boasting a mere 80 residences.
          A G+ 21 apartment structure in Sarjapur, Bangalore, with variations of
          3/4/5 BHK, that comes with 3 parking spaces, presidential penthouses,
          and is packaged with the best of what life has to offer. <br /> <br />
            These lavish flats in Sarjapur road serve as a testament to your
          elevated standing, triumphs, innate elegance and impeccable taste.
          Within this sanctuary, you’ll find kindred spirits who embrace the
          same philosophy of prosperous living.
          <br /> <br /> Here, you become a cherished member of a noteworthy
          community that offers something captivating for every soul, regardless
          of age.
        </p>
        {/* this button scroll to contact and then opens the contact form */}
        {/* <button
          onClick={onRegisterClick}
          className={styles.btn + " btn  lunativeFont "}
        >
          Request Callback
        </button> */}

        <ContactUs />
      </div>
    </div>
  );
}

export default Section2;
