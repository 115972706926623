import React from "react";
import styles from "./Section4.module.scss";
import rightImg from "../../../assets/images/HomeSec4RightImg.jpg";
import bgImg from "../../../assets/images/HomeSec4img.jpg";

import GrandLobby from "../../../assets/icons/section4/grandLobby.svg";
import PanoramicView from "../../../assets/icons/section4/panoramicViews.svg";
import BiformClubhouse from "../../../assets/icons/section4/birformClubhouse.svg";
import UnlockMore from "../../../assets/icons/section4/unlockMore.svg";
import HomeAutomation from "../../../assets/icons/section4/homeAutomation.svg";
import PrivatePlanters from "../../../assets/icons/section4/privatePlanters.svg";
import ConceirgeServices from "../../../assets/icons/section4/conceirgeServices.svg";
import LeisurePavillion from "../../../assets/icons/section4/leisurePavillion.svg";
import OutdoorWorkpod from "../../../assets/icons/section4/outdoorWorkpod.svg";
import AmphiTheatre from "../../../assets/icons/section4/amphitheathre.svg";

import useScrollReveal from "../../../hooks/useScrollReveal";

function Section4({ onRegisterClick }) {
  const [leftRef, leftVisible] = useScrollReveal("left");
  const [rightRef, rightVisible] = useScrollReveal("right");

  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
        <div ref={leftRef} className={`${styles.leftDiv} appearFromLeft`}>
          <div className={styles.leftContainer}>
            <h1 className={styles.textTitle + " textTitle lunativeFont "}>
              Access To:
            </h1>
            <h1 className={styles.textTitle + " textTitle "}>Distinctivity</h1>
            <p className={styles.bodyText + " bodyText "}>
              You are the unrivalled focal point of a meticulously crafted opus,
              a world curated to elevate each moment of your existence. <br /> 
              <br />{" "}
              <span className={styles.italic}>
                You have indeed arrived in life
              </span>
              , where nothing but the absolute best awaits your discerning
              taste.
            </p>
            <div className={styles.lastDiv}>
              <div className={styles.labelImgDiv}>
                <div className={styles.imgDiv}>
                  <img
                    src={GrandLobby}
                    alt="Grand Lobby"
                    className={styles.iconImg}
                  />
                </div>
                <p className={styles.iconLabel}>Grand Lobby</p>
              </div>
              <div className={styles.labelImgDiv}>
                <div className={styles.imgDiv}>
                  <img
                    src={PanoramicView}
                    alt="Panoramic View"
                    className={styles.iconImg}
                  />
                </div>
                <p className={styles.iconLabel}>Panoramic Views</p>
              </div>
              <div className={styles.labelImgDiv}>
                <div className={styles.imgDiv}>
                  <img
                    src={BiformClubhouse}
                    alt="Biform Clubform"
                    className={styles.iconImg}
                  />
                </div>
                <p className={styles.iconLabel}>Triple Wellness Clubs</p>
              </div>
              <div className={styles.labelImgDiv} onClick={onRegisterClick}>
                <div className={styles.imgDiv + " unlockMore "}>
                  <img
                    src={UnlockMore}
                    alt="unlock More"
                    className={styles.iconImg}
                  />
                </div>
                <p className={styles.iconLabel}>Unlock More</p>
              </div>
            </div>
          </div>
        </div>
        <div ref={rightRef} className={`${styles.rightDiv} appearFromRight`}>
          <img src={bgImg} alt="Image" className={styles.rightImg} />
        </div>
      </div>
    </div>
  );
}

export default Section4;
