import React from 'react';
import styles from './Terms.module.scss'; // Update the style file name if you have a separate style file for terms of use
import { useNavigate } from 'react-router-dom';

function Terms() { // Renamed the component to reflect its new purpose
  const navigate = useNavigate();

  // Handler for the 'Back' button which navigates to the previous page
  const goBack = () => {
    navigate(-1);  // This goes back to the previous page
  };

  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
        <button onClick={goBack} className={styles.backButton}>Go Back</button>

        <h1>Terms of Use</h1>
        <p>Last Updated: 10 OCT 2023</p> {/* Be sure to replace [Insert Date Here] with the actual last updated date */}
        
        <p>Welcome to Mana Jardin Neo.</p> 
        <p>By using our website, you agree to abide by these terms. We're thrilled to have you with us, but let's lay down some ground rules.</p>
        
        <h2>1. Use of Content:</h2>
        <p>All content on this website, including text, images, and designs, is the property of Mana Jardin Neo. Please enjoy it, but don't use it for anything other than personal or non-commercial purposes without our permission.</p>
        
        <h2>2. User Conduct:</h2>
        <p>Conduct yourself with decorum. Activities such as malicious intent, spamming, or any actions that may compromise the integrity of our website or infringe upon the experience of other users are strictly prohibited.</p>

        <h2>3. Accuracy of Information:</h2>
        <p>While we endeavor to maintain accuracy, human error may occur. We disclaim responsibility for any decisions made based on information derived from this website.</p>

        <h2>4. Third-Party Links:</h2>
        <p>This website may contain links to third-party websites. We relinquish control over their content and disclaim any responsibility for issues arising from interactions with these external sources.</p>

        <h2>5. Privacy:</h2>
        <p>Respecting your privacy is paramount. Kindly review our Privacy Policy to comprehend our approach to handling user data.</p>

        <h2>6. Changes to Terms:</h2>
        <p>We retain the right to modify these terms at our discretion. Regularly review this section to ensure ongoing alignment with your agreement.</p>

        <h2>7. Termination:</h2>
        <p>In instances of non-compliance with these terms, we reserve the right to terminate user access.</p>

        <h2>8. Contact Us:</h2>
        <p>For inquiries or clarification, please direct your communication to +91 9886701064.</p>

        <p>Thank you for choosing Mana Jardin Neo</p>
      </div>
    </div>
  );
}

export default Terms; // Don't forget to change the component name here as well
