import React, { useState } from "react";
import styles from "./Section12.module.scss";
import Map from "../../../assets/images/cusMap.png";
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
// import Map from "../../../components/map/map"
// import useScrollReveal from '../../../hooks/useScrollReveal';

function Section12() {
  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <h1 className={styles.textTitleX + " textTitle lunativeFont "}>
        Locate Us
      </h1>
      <div className={styles.secondDiv + " secondDiv "}>
        <img src={Map} alt="Map Image" className={styles.mapImg} />
      </div>
    </div>
  );
}

export default Section12;

// const [leftRef, leftVisible] = useScrollReveal('left');
// const [rightRef, rightVisible] = useScrollReveal('right');

//   const [expandedSection, setExpandedSection] = useState(null);
// const locationsData = [
//   {
//     name: "SCHOOLS",
//     details: [
//       { name: "Harvest International School", time: " 12 mins " },
//       { name: "Primus Public School	 ", time: "14 mins" }
//     ]
//   },
//   {
//     name: "TECH",
//     details: [
//       { name: "Wipro SEZ", time: " 6 mins" },
//     ]
//   },
//   {
//     name: "HOSPITALS",
//     details: [
//       { name: "Belenus Champion Hospital", time: "6 mins" },
//       { name: "Mark school", time: "7 Mins" }
//     ]
//   },
//   {
//     name: "CONNECTIVITY",
//     details: [
//       { name: "Kempegowda International Airport		 ", time: "7 Mins" },
//       { name: "Krantiveera Sangolli Rayanna Railway Station", time: "7 Mins" },
//       { name: "Carmelaram Bus Terminal	", time: "5 mins" }
//     ]
//   },
// ];

// const toggleSection = (sectionName) => {
//   if (expandedSection === sectionName) {
//     setExpandedSection(null);
//   } else {
//     setExpandedSection(sectionName);
//   }
// };

//   return (
//     <div className={styles.rootDiv + " rootDiv "}>
//               <h1 className={styles.textTitleX + " textTitle lunativeFont "}>
//             Locate Us
//           </h1>
//       <div className={styles.secondDiv + " secondDiv "}>

//       <div ref={leftRef} className={`${styles.leftContainer} appearFromLeft`}>

//           {locationsData.map((location, index) => (
//             <div key={index} onClick={() => toggleSection(location.name)} >
//               <div className={styles.expandableContainer}>
//                 <div className={styles.expandableHeader + "  "}>{location.name} </div>
//                 <span className={styles.iconContainer}>
//                   <FontAwesomeIcon icon={expandedSection === location.name ? faMinus : faPlus} className={styles.faIcon}/>
//                 </span>
//               </div>
//               <div className={styles.values}>
//                 {expandedSection === location.name && location.details.map((detail, detailIndex) => (
//                   <p key={detailIndex}>{detail.name} - {detail.time}</p>
//                 ))}
//               </div>
//             </div>
//           ))}

//         </div>

//         <div ref={rightRef} className={`${styles.rightContainer} appearFromRight`}>

//         <Map/>
//          </div>

//       </div></div>
//   )
// }

// export default Section12
