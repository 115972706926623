import { useEffect, useState, useRef } from 'react';

const useScrollReveal = (direction = 'left') => {
  const [isVisible, setIsVisible] = useState(false);
  const ref = useRef(null);

  const handleScroll = () => {
    if (ref.current) {
      const rect = ref.current.getBoundingClientRect();
      const elementHeight = rect.height;
      const visiblePart = window.innerHeight - rect.top;

      if (visiblePart > 0 && visiblePart < elementHeight) { // This ensures the effect happens only within the boundary of the element
        const progress = Math.min(visiblePart / elementHeight, 1);
        const translateValue = (1 - progress) * (direction === 'left' ? -100 : 100);
        ref.current.style.transform = `translateX(${translateValue}%)`;
      } else if (visiblePart <= 0) { // Element is above viewport
        ref.current.style.transform = `translateX(${direction === 'left' ? -100 : 100}%)`;
      } else { // Element is below viewport
        ref.current.style.transform = `translateX(0%)`;
      }
    }
};


  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0.1,
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return [ref, isVisible];
};

export default useScrollReveal;