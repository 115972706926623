// Thankyou.jsx

import React from "react";
import { Helmet } from "react-helmet"; // Ensure Helmet is imported

import styles from "./Thankyou.module.scss"; // Assuming you use the same styles
import { useNavigate } from "react-router-dom"; // Importing useHistory

function ThankYou() {
  const navigate = useNavigate(); // Initializing the navigate function

  const goToHomePage = () => {
    navigate("/");
  };
  return (
    <div className={styles.rootDiv + " rootDiv "}>
       {/* <Helmet> */}
        {/* Google Analytics (gtag.js) - This script will only be loaded with the Thank You page */}
        {/* <script async src="https://www.googletagmanager.com/gtag/js?id=AW-744349269"></script> */}
        {/* <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-744349269');
          `}
        </script> */}
      {/* </Helmet> */}
      <div className={styles.thankyouLeft + " lunativeFont  "}> THANK YOU!</div>
      <div className={styles.thankYouDiv}>
        <div className={styles.thankYouQuote}>
          <span>“</span>
          Thank you for taking this felicitous step, bringing you one stride
          closer to embracing the exquisite world of Jardin Neo.
          <span>”</span>
        </div>
        <div className={styles.followUpHeader}>What Follows:</div>
        <ul className={styles.followUpList}>
          <li className={styles.list}>
            Our team will connect with you to demonstrate an immersive VR
            experience at your residence
          </li>
          <li className={styles.list}>
            Our representatives will personally assist in the selection of your
            unit.
          </li>
          <li className={styles.list}>
            You will have access to a personal account manager ushering you from
            booking to handover.
          </li>
        </ul>
        <button className={styles.closeButton} onClick={goToHomePage}>
          Close
        </button>
      </div>
    </div>
  );
}

export default ThankYou;
