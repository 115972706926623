import React, { useEffect } from 'react';
import styles from "./Section1.module.scss";
import ManaLogo from "../../../assets/logo/ManaLogo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import useOnScrollMoveUp from "../../../hooks/useScrollEffect"; // Adjust the import path accordingly
import Rellax from 'rellax';
import { faVrCardboard } from "@fortawesome/free-solid-svg-icons"; // Use an actual FontAwesome icon that suits your needs



function Section1({ onRegisterClick }) {
  useEffect(() => {
    new Rellax('.rellax', {
      // Your options here, for example:
      speed: -2,
      center: false,
      wrapper: null,
      round: true,
      vertical: true,
      horizontal: false
    });
  }, []);
  const { elementRef, animationStyle } = useOnScrollMoveUp();


  return (
    <div ref={elementRef} className={styles.rootDiv + " rootDiv "}>
      <div  className={styles.secondDiv + " secondDiv rellax "}>
        <div className={styles.topDiv}>
          <div className={styles.logoContainer}>
            <img src={ManaLogo} alt="Logo Image" className={styles.logoImg} />
          </div>
        </div>

      </div>
    </div>
  );
}

export default Section1;
