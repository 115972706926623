import React from "react";
import styles from "./vr.module.scss";
import { useNavigate } from "react-router-dom"; // Updated import

function Vr() {
  const navigate = useNavigate(); // Updated hook

  const goBack = () => {
    navigate(-1); // Updated method to go back
  };

  return (
    <div className={styles.vr}>
      <button className={styles.backBtn} onClick={goBack}>
        Back
      </button>
      <iframe src="/VR/index.htm" width="100%" height="100%"></iframe>
    </div>
  );
}

export default Vr;
