import React from "react";
import styles from "./Section5.module.scss";
import useScrollReveal from "../../../hooks/useScrollReveal"; // Update the path

function Section5({ onRegisterClick }) {
  const [leftRef, leftVisible] = useScrollReveal("left");
  const [rightRef, rightVisible] = useScrollReveal("right");

  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
        <div
          ref={leftRef}
          className={`${styles.leftDiv} appearFromLeft secondDiv`}
        >
          <h1 className={styles.textTitle + " textTitle lunativeFont"}>
            Come. <br />
            Belong.
          </h1>
        </div>
        <div ref={rightRef} className={`${styles.rightDiv} appearFromRight`}>
          <p className={styles.bodyText + " bodyText "}>
            <span>To access the exclusive world of Mana Jardin Neo</span> <br />
            <p className={styles.firstLineBold + " bodyText "}>
              which stands at the peak of premium living, securing your
              invitation is essential, given its limited availability. Will you
              be amongst the privileged few who recognize the exceptional appeal
              of this project?
            </p>
          </p>
          <button onClick={onRegisterClick} className={styles.btn + " btn "}>
            Enquire Now
          </button>
        </div>
      </div>
    </div>
  );
}

export default Section5;
