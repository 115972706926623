import React from "react";
import styles from "./Section4p2.module.scss";
import rightImg from "../../../assets/images/HomeSec4RightImg.jpg";
import bgImg from "../../../assets/images/HomeSec4img2.jpg";

import GrandLobby from "../../../assets/icons/section4/grandLobby.svg";
import PanoramicView from "../../../assets/icons/section4/panoramicViews.svg";
import BiformClubhouse from "../../../assets/icons/section4/birformClubhouse.svg";
import UnlockMore from "../../../assets/icons/section4/unlockMore.svg";
import HomeAutomation from "../../../assets/icons/section4/homeAutomation.svg";
import PrivatePlanters from "../../../assets/icons/section4/privatePlanters.svg";
import ConceirgeServices from "../../../assets/icons/section4/conceirgeServices.svg";
import LeisurePavillion from "../../../assets/icons/section4/leisurePavillion.svg";
import OutdoorWorkpod from "../../../assets/icons/section4/outdoorWorkpod.svg";
import AmphiTheatre from "../../../assets/icons/section4/amphitheathre.svg";

import useScrollReveal from "../../../hooks/useScrollReveal";

function Section4p2({ onRegisterClick }) {
  const [leftRef, leftVisible] = useScrollReveal("left");
  const [rightRef, rightVisible] = useScrollReveal("right");

  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
        <div ref={leftRef} className={`${styles.leftDiv} appearFromLeft`}>
          <div className={styles.leftContainer}>
            <h1 className={styles.textTitle + " textTitle lunativeFont "}>
              Access To:
            </h1>
            <h1 className={styles.textTitle + " textTitle "}>Community</h1>
            <p className={styles.bodyText + " bodyText "}>
              Arrive at our exclusive, members-only realm. A sanctuary for those
              who've worked their way to the upper echelons of success, where
              every facet reminds you of your achievements and makes you truly
              feel the part. <br /> <br />
              Membership here is not bestowed lightly; it’s a coveted privilege,
              accessible solely through
              <span className={styles.bold}> invitation</span>
            </p>
            <div className={styles.lastDiv}>
              <div className={styles.labelImgDiv}>
                <div className={styles.imgDiv}>
                  <img
                    src={LeisurePavillion}
                    alt="Leisure Pavillion"
                    className={styles.iconImg}
                  />
                </div>
                <p className={styles.iconLabel}>Leisure Pavilion</p>
              </div>
              <div className={styles.labelImgDiv}>
                <div className={styles.imgDiv}>
                  <img
                    src={OutdoorWorkpod}
                    alt="Outdoor Workpod"
                    className={styles.iconImg}
                  />
                </div>
                <p className={styles.iconLabel}>Outdoor Workpod</p>
              </div>
              <div className={styles.labelImgDiv}>
                <div className={styles.imgDiv}>
                  <img
                    src={AmphiTheatre}
                    alt="Amphitheatre"
                    className={styles.iconImg}
                  />
                </div>
                <p className={styles.iconLabel}>Amphitheatre</p>
              </div>
              <div className={styles.labelImgDiv} onClick={onRegisterClick}>
                <div className={styles.imgDiv + " unlockMore "}>
                  <img
                    src={UnlockMore}
                    alt="unlock More"
                    className={styles.iconImg + "  "}
                  />
                </div>
                <p className={styles.iconLabel}>Unlock More</p>
              </div>
            </div>
          </div>
        </div>
        <div ref={rightRef} className={`${styles.rightDiv} appearFromRight`}>
          <img src={bgImg} alt="Image" className={styles.rightImg} />
        </div>
      </div>
    </div>
  );
}

export default Section4p2;
