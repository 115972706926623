import React from "react";
import styles from "./Section4p1.module.scss";
import rightImg from "../../../assets/images/HomeSec4RightImg.jpg";
import bgImg from "../../../assets/images/HomeSec4img1.jpg";

import GrandLobby from "../../../assets/icons/section4/grandLobby.svg";
import PanoramicView from "../../../assets/icons/section4/panoramicViews.svg";
import BiformClubhouse from "../../../assets/icons/section4/birformClubhouse.svg";
import UnlockMore from "../../../assets/icons/section4/unlockMore.svg";
import HomeAutomation from "../../../assets/icons/section4/homeAutomation.svg";
import PrivatePlanters from "../../../assets/icons/section4/privatePlanters.svg";
import ConceirgeServices from "../../../assets/icons/section4/conceirgeServices.svg";
import LeisurePavillion from "../../../assets/icons/section4/leisurePavillion.svg";
import OutdoorWorkpod from "../../../assets/icons/section4/outdoorWorkpod.svg";
import AmphiTheatre from "../../../assets/icons/section4/amphitheathre.svg";

import useScrollReveal from "../../../hooks/useScrollReveal";

function Section4p1({ onRegisterClick }) {
  const [leftRef, leftVisible] = useScrollReveal("left");
  const [rightRef, rightVisible] = useScrollReveal("right");

  return (
    <div className={styles.rootDiv + " rootDiv "}>
      <div className={styles.secondDiv + " secondDiv "}>
        <div ref={leftRef} className={`${styles.rightDiv} appearFromLeft`}>
          <img src={bgImg} alt="Image" className={styles.rightImg} />
        </div>
        <div ref={rightRef} className={`${styles.leftDiv} appearFromRight`}>
          <div className={styles.leftContainer}>
            <h1 className={styles.textTitle + " textTitle lunativeFont "}>
              Access To:
            </h1>
            <h1 className={styles.textTitle + " textTitle "}>Priority</h1>
            <p className={styles.bodyText + " bodyText "}>
              Here, your needs are not simply met, but anticipated, from
              cutting-edge home automation to a host of exclusive amenities.
              Priority grants you access to a world where indulgence knows no
              bounds, where every detail reflects your stature, and where
              excellence is the only standard.
            </p>
            <h1 className={styles.tagText + "  "}>
              You are the essence of priority.
            </h1>
            <div className={styles.lastDiv}>
              <div className={styles.labelImgDiv}>
                <div className={styles.imgDiv}>
                  <img
                    src={HomeAutomation}
                    alt="Home Automation"
                    className={styles.iconImg}
                  />
                </div>
                <p className={styles.iconLabel}>Home Automation</p>
              </div>
              <div className={styles.labelImgDiv}>
                <div className={styles.imgDiv}>
                  <img
                    src={PrivatePlanters}
                    alt="Private Planters"
                    className={styles.iconImg}
                  />
                </div>
                <p className={styles.iconLabel}>Private Planters</p>
              </div>
              <div className={styles.labelImgDiv}>
                <div className={styles.imgDiv}>
                  <img
                    src={ConceirgeServices}
                    alt="Conceirge Services"
                    className={styles.iconImg}
                  />
                </div>
                <p className={styles.iconLabel}>Conceirge Services</p>
              </div>
              <div className={styles.labelImgDiv} onClick={onRegisterClick}>
                <div className={styles.imgDiv + " unlockMore "}>
                  <img
                    src={UnlockMore}
                    alt="unlock More"
                    className={styles.iconImg + "  "}
                  />
                </div>
                <p className={styles.iconLabel}>Unlock More</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Section4p1;
